import { useEffect, useCallback, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import routes from "./router";
import Status404 from "src/content/Status404";

import { useAppSelector } from "src/redux/hooks";
import { routePath } from "./layouts/SidebarLayout/Sidebar/SidebarMenu/items";

const AuthModule = () => {
  const navigate = useNavigate();
  const { listModuleAccess = [], token } = useAppSelector((state) => state.auth);
  const [listRoute, setRoute] = useState<any[]>([]);

  const handleMenu = useCallback(() => {
    let listRoute: any[] = [
      {
        path: routePath.NOT_FOUND.pathName,
        element: <Status404 />,
      },
    ];

    for (const { path: parentPath, element: parentElement, children } of routes) {
      for (const { moduleId, canRead } of listModuleAccess) {
        // if have a child
        if (children) {
          for (const { id, path, element } of children) {
            const index = listRoute.findIndex((prev) => prev.path === parentPath);

            if (moduleId === id && canRead === 1) {
              if (index === -1) {
                listRoute.unshift({
                  path: parentPath,
                  element: parentElement,
                  children: [{ id, path, element }],
                });
              } else {
                listRoute[index].children.push({ id, path, element });
              }
            }

            if (id === "allow" && index === -1) {
              listRoute.unshift({
                path: parentPath,
                element: parentElement,
                children: [{ id, path, element }],
              });
            }
          }
        }
      }
    }

    setRoute((prev) => (prev = listRoute));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listModuleAccess]);

  useEffect(() => {
    if (token === null) navigate(routePath.SIGNIN.pathName, { replace: true });

    if (token !== null) handleMenu();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleMenu]);

  return (
    <Routes>
      {listRoute.map((route, i) =>
        route.children ? (
          <Route key={i} path={route.path} element={route.element} caseSensitive={true}>
            {route.children.map((child: any, j: number) => (
              <Route key={j} path={child.path} element={child.element} caseSensitive={true} />
            ))}
          </Route>
        ) : (
          <Route key={i} path={route.path} element={route.element} caseSensitive={true} />
        )
      )}
      <Route path="*" element={<Status404 />} />
    </Routes>
  );
};

export default AuthModule;
