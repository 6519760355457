import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface State {
  listTunggakan: any[];
  listTunggakanDetail: any[];
  isLoading: boolean;
  id: string;
  count: number;
  currentPage: number;
  totalItem: number;
  hasMore: boolean;
  limitReq: number;
  limitReqPage: number;
}

const initialState: State = {
  listTunggakan: [],
  listTunggakanDetail: [],
  isLoading: false,
  id: "",
  count: 1,
  currentPage: 1,
  totalItem: 20,
  hasMore: true,
  limitReqPage: 1,
  limitReq: 20,
};

const slice = createSlice({
  name: "tunggakan",
  initialState,
  reducers: {
    handleChange: (state: State, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    cleanScroll: (state: State) => {
      state.limitReqPage = 1;
      state.hasMore = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTunggakan.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(getAllTunggakan.fulfilled, (state, action) => {
        const { data, count } = action.payload;
        return {
          ...state,
          isLoading: false,
          listTunggakan: data,
          count,
        };
      })
      .addCase(getAllTunggakan.rejected, (state) => {
        return {
          ...state,
          isLoading: false,
          listTunggakan: [],
          count: 1,
        };
      })
      .addCase(getTunggakanById.pending, (state) => {
        if (state.limitReqPage === 1) {
          state.isLoading = true;
        }
      })
      .addCase(getTunggakanById.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.isLoading = false;
        state.listTunggakanDetail = data;
        if (data.length < state.limitReq) {
          state.hasMore = false;
        }
      })
      .addCase(getTunggakanById.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getTunggakanByIdNext.fulfilled, (state, { payload }) => {
        if (payload.data !== undefined && payload.data.length === 0) {
          state.hasMore = false;
        }

        if (payload.data !== undefined && payload.data.length !== 0) {
          state.limitReqPage += 1;
          state.listTunggakanDetail = [...state.listTunggakanDetail, ...payload.data];
        }
      })
      .addCase(lunaskanTunggakanById.fulfilled, (state, { meta, payload }) => {
        const { data } = payload;
        const { billingHistoryId } = meta.arg;
        const index = state.listTunggakanDetail.findIndex(
          (prev) => prev.billingHistoryId === billingHistoryId
        );
        const newValue = {
          ...state.listTunggakanDetail[index],
          isApproved: 1,
        };
        state.listTunggakanDetail[index] = newValue;

        if (data !== undefined && data.length > 0)
          state.listTunggakanDetail = [...data, ...state.listTunggakanDetail];
      });
  },
});
export const { handleChange, cleanScroll } = slice.actions;
export default slice.reducer;

export const getAllTunggakan = createAsyncThunk(
  "getAllTunggakan",
  async (data: { totalItem: number; currentPage: number }) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_CLIENT_API_URL}/tunggakan?totalItem=${data.totalItem}&page=${data.currentPage}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const getTunggakanById = createAsyncThunk(
  "getTunggakanById",
  async (data: { homeDetailId: string; limitReq: number; limitReqPage: number }) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_CLIENT_API_URL}/tunggakan/${data.homeDetailId}?limit=${data.limitReq}&page=${data.limitReqPage}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const getTunggakanByIdNext = createAsyncThunk(
  "getTunggakanByIdNext",
  async (data: { homeDetailId: string; limitReq: number; limitReqPage: number }) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_CLIENT_API_URL}/tunggakan-next/${data.homeDetailId}?limit=${data.limitReq}&page=${data.limitReqPage}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const lunaskanTunggakanById = createAsyncThunk(
  "lunaskanTunggakanById",
  async (data: { billingHistoryId: string; homeDetailId: string }) => {
    try {
      const response = await axiosInstance.put(
        `${process.env.REACT_APP_CLIENT_API_URL}/lunas-tunggakan/${data.billingHistoryId}/${data.homeDetailId}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);
