import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface state {
  listQuestion: any[];
  editMode: boolean;
  isLoading: boolean;
  showModal: boolean;
  id: string;
  editValue: {};
  count: number;
  currentPage: number;
  totalItem: number;
}

const initialState: state = {
  listQuestion: [],
  editMode: false,
  isLoading: true,
  showModal: false,
  id: "",
  editValue: {},
  count: 1,
  currentPage: 1,
  totalItem: 20,
};

const slice = createSlice({
  name: "question warga",
  initialState,
  reducers: {
    handleChange: (state, { payload }) => {
      state.currentPage = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getllQuestion.fulfilled, (state, { payload }) => {
      const { data, count } = payload;
      return {
        ...state,
        isLoading: false,
        listQuestion: data,
        count,
      };
    });
    builder.addCase(getllQuestion.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
export const { handleChange } = slice.actions;
export default slice.reducer;

export const getllQuestion = createAsyncThunk(
  "getllQuestion",
  async (data: { totalItem: number; currentPage: number }) => {
    try {
      const response = await axiosInstance.get(
        `/question?totalItem=${data.totalItem}&page=${data.currentPage}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const editQuestion = createAsyncThunk("editQuestion", async (data: any) => {
  try {
    const response = await axiosInstance.put(`/question`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});
