import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Box, Card, CardContent, CardActions } from "@mui/material";

import Layout from "src/components/Layout";
import { SwalAlertModal } from "src/components/SweetAlert";
import { OutlineInput, OutlineInputPassword } from "../../components/OutlineInput";
import logoUrl from "../../images/logo.png";
import { routePath } from "../../layouts/SidebarLayout/Sidebar/SidebarMenu/items";

// redux
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { showPassword, login, setUserData, onChangeLogin, loginEmployees } from "src/redux/auth";

const Signin = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const dispatch = useAppDispatch();
  const { isLoading, isShowPassword, asEmployee, token } = useAppSelector((state) => state.auth);

  const effect = () => {
    if (token !== null) navigate(routePath.DASHBOARD.pathName, { replace: true });
  };

  const onSubmit = (data: any) => {
    const value = {
      resident: data.resident !== undefined ? data.resident.trim() : "",
      username: data.username.trim(),
      password: data.password.trim(),
    };

    if (!asEmployee) {
      dispatch(login(value))
        .unwrap()
        .then(() => {
          dispatch(setUserData());
          navigate(routePath.DASHBOARD.pathName, { replace: true });
        })
        .catch((err) => SwalAlertModal("Gagal", err.message, "error"));
    } else {
      dispatch(loginEmployees(value))
        .unwrap()
        .then(() => {
          dispatch(setUserData());
          navigate(routePath.DASHBOARD.pathName, { replace: true });
        })
        .catch((err) => SwalAlertModal("Gagal", err.message, "error"));
    }
  };

  const changeLogin = () => {
    reset();
    dispatch(onChangeLogin());
  };

  return (
    <Layout title="Sigin in" effectCallback={effect}>
      <Box sx={{ display: "flex", justifyContent: "center", boxShadow: 3, marginTop: 15 }}>
        <Card sx={{ width: 430 }}>
          <div style={{ textAlign: "center", paddingTop: 20, paddingBottom: 10 }}>
            <img src={logoUrl} alt="sentris logo" />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              {asEmployee &&
                OutlineInput({
                  control,
                  inputName: "resident",
                  label: "Resident",
                  errors: errors.resident !== undefined,
                  rules: {
                    required: {
                      value: asEmployee ? true : false,
                      message: "Required!",
                    },
                  },
                  placeholder: "Resident",
                  disabled: isLoading,
                })}
              {OutlineInput({
                control,
                inputName: "username",
                label: "Username",
                errors: errors.username !== undefined,
                rules: { required: { value: true, message: "Required!" } },
                placeholder: "Username",
                disabled: isLoading,
              })}
              {OutlineInputPassword({
                control,
                inputName: "password",
                label: "Password",
                showPass: isShowPassword,
                errors: errors.password !== undefined,
                onTap: () => dispatch(showPassword()),
                rules: { required: { value: true, message: "Required!" } },
                disabled: isLoading,
              })}
            </CardContent>
            <Box sx={{ justifyContent: "center", textAlign: "center" }}>
              <Button type="button" size="small" onClick={changeLogin}>
                Login as {asEmployee === false ? "Employees" : "Resident"}?
              </Button>
            </Box>
            <CardActions sx={{ justifyContent: "center", flexDirection: "column" }}>
              <Button type="submit" variant="contained" disabled={isLoading}>
                Login
              </Button>
            </CardActions>
          </form>
        </Card>
      </Box>
    </Layout>
  );
};

export default Signin;
