import loadable from "@loadable/component";

import { routePath } from "src/layouts/SidebarLayout/Sidebar/SidebarMenu/items";
import SidebarLayout from "src/layouts/SidebarLayout";

const Dashboard = loadable(() => import("src/content/dashboard"));
const Announcement = loadable(() => import("src/content/announcement"));

const Komplek = loadable(() => import("src/content/management-resident/komplek"));
const Warga = loadable(() => import("src/content/management-resident/warga"));

const Tunggakan = loadable(() => import("src/content/billing-ipl/tunggakan"));
const TunggakanDetail = loadable(() => import("src/content/billing-ipl/tunggakan-detail"));
const BillingPayment = loadable(() => import("src/content/billing-ipl/billing-payment"));
const DetailBilling = loadable(() => import("src/content/billing-ipl/detail-billing"));

const Complaint = loadable(() => import("src/content/helpdesk/complaint"));
const Question = loadable(() => import("src/content/helpdesk/question"));

const Merchants = loadable(() => import("src/content/merchants"));

const Visitor = loadable(() => import("src/content/gate-history/visitor"));
const UnknownVisitor = loadable(() => import("src/content/gate-history/visitor-unknown"));

const Roles = loadable(() => import("src/content/management-team/roles"));
const Employees = loadable(() => import("src/content/management-team/employees"));
const Permissions = loadable(() => import("src/content/management-team/permissions"));
const RolePermissions = loadable(
  () => import("src/content/management-team/permissions/role-permission")
);
const Security = loadable(() => import("src/content/management-team/security"));

const Contact = loadable(() => import("src/content/emergency/call"));
const History = loadable(() => import("src/content/emergency/history"));

const Bank = loadable(() => import("src/content/bank"));

const Services = loadable(() => import("src/content/services"));
const Request = loadable(() => import("src/content/services/request"));

const Status404 = loadable(() => import("src/content/Status404"));

const routes = [
  {
    path: "/",
    element: <SidebarLayout />,
    children: [
      {
        id: "allow",
        path: routePath.DASHBOARD.pathName,
        element: <Dashboard />,
      },
      // Announcement
      {
        id: routePath.ANNOUNCEMENT.id,
        path: routePath.ANNOUNCEMENT.pathName,
        element: <Announcement />,
      },
      // management resident
      {
        id: routePath.KOMPLEK.id,
        path: routePath.KOMPLEK.pathName,
        element: <Komplek />,
      },
      {
        id: routePath.WARGA.id,
        path: routePath.WARGA.pathName,
        element: <Warga />,
      },
      // Billing/IPL
      {
        id: routePath.TUNGGAKAN.id,
        path: routePath.TUNGGAKAN.pathName,
        element: <Tunggakan />,
      },
      {
        id: routePath.TUNGGAKAN_DETAIL.id,
        path: routePath.TUNGGAKAN_DETAIL.pathName,
        element: <TunggakanDetail />,
      },
      {
        id: routePath.BILLING_PAYMENT.id,
        path: routePath.BILLING_PAYMENT.pathName,
        element: <BillingPayment />,
      },
      {
        id: routePath.DETAIL_BILLING.id,
        path: routePath.DETAIL_BILLING.pathName,
        element: <DetailBilling />,
      },
      // helpdesk
      {
        id: routePath.COMPLAINT.id,
        path: routePath.COMPLAINT.pathName,
        element: <Complaint />,
      },
      {
        id: routePath.QUESTION.id,
        path: routePath.QUESTION.pathName,
        element: <Question />,
      },
      // merchants
      {
        id: routePath.MERCHANTS.id,
        path: routePath.MERCHANTS.pathName,
        element: <Merchants />,
      },
      // management team
      {
        id: routePath.ROLES.id,
        path: routePath.ROLES.pathName,
        element: <Roles />,
      },
      {
        id: routePath.EMPLOYEES.id,
        path: routePath.EMPLOYEES.pathName,
        element: <Employees />,
      },
      {
        id: routePath.PERMISSION.id,
        path: routePath.PERMISSION.pathName,
        element: <Permissions />,
      },
      {
        id: routePath.ROLE_PERMISSION.id,
        path: routePath.ROLE_PERMISSION.pathName,
        element: <RolePermissions />,
      },
      {
        id: routePath.SECURITY.id,
        path: routePath.SECURITY.pathName,
        element: <Security />,
      },
      // gate history
      {
        id: routePath.VISITOR.id,
        path: routePath.VISITOR.pathName,
        element: <Visitor />,
      },
      {
        id: routePath.UNKNOW_VISITOR.id,
        path: routePath.UNKNOW_VISITOR.pathName,
        element: <UnknownVisitor />,
      },
      // emergency
      {
        id: routePath.EMERGENCY_CONTACT.id,
        path: routePath.EMERGENCY_CONTACT.pathName,
        element: <Contact />,
      },
      {
        id: routePath.EMERGENCY_HISTORY.id,
        path: routePath.EMERGENCY_HISTORY.pathName,
        element: <History />,
      },
      // bank
      {
        id: routePath.BANK.id,
        path: routePath.BANK.pathName,
        element: <Bank />,
      },
      // service
      {
        id: routePath.SERVICE.id,
        path: routePath.SERVICE.pathName,
        element: <Services />,
      },
      {
        id: routePath.SERVICE_REQUEST.id,
        path: routePath.SERVICE_REQUEST.pathName,
        element: <Request />,
      },
    ],
  },
  {
    path: "/not-found",
    element: <Status404 />,
  },
];

export default routes;
