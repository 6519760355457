import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import jwt_decode from "jwt-decode";

interface AuthState {
  isLoading: boolean;
  asEmployee: boolean;
  listModuleAccess: any[];
  token: string | null;
  isShowPassword: boolean;
  isShowConfPassword: boolean;
  name: string;
}

const initialState: AuthState = {
  isLoading: false,
  asEmployee: false,
  token: localStorage.getItem(process.env.REACT_APP_AUTH as string),
  listModuleAccess: [],
  isShowPassword: false,
  isShowConfPassword: false,
  name: "",
};

const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setUserData: (state) => {
      if (state.token !== null) {
        const splitToken = state.token!.split(" ");
        const decodeToken: any = jwt_decode(splitToken[1]);
        state.name = decodeToken.username;
        state.listModuleAccess = decodeToken.listModuleAccess;
      }
    },
    showPassword: (state) => {
      state.isShowPassword = !state.isShowPassword;
    },
    showConfPassword: (state) => {
      return { ...state, isShowConfPassword: !state.isShowConfPassword };
    },
    logout: (state) => {
      localStorage.removeItem(process.env.REACT_APP_AUTH as string);
      return {
        ...state,
        name: "",
        subname: "",
        token: null,
      };
    },
    onChangeLogin: (state) => {
      state.asEmployee = !state.asEmployee;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        return {
          ...state,
          isLoading: true,
          isShowPassword: false,
        };
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        const { Authorization }: any = payload;
        localStorage.setItem(process.env.REACT_APP_AUTH as string, Authorization);
        return {
          ...state,
          isLoading: false,
          token: Authorization,
        };
      })
      .addCase(login.rejected, (state) => {
        return {
          ...state,
          isLoading: false,
          isShowPassword: false,
        };
      })
      .addCase(loginEmployees.pending, (state) => {
        return {
          ...state,
          isLoading: true,
          isShowPassword: false,
        };
      })
      .addCase(loginEmployees.fulfilled, (state, { payload }) => {
        const { Authorization }: any = payload;
        localStorage.setItem(process.env.REACT_APP_AUTH as string, Authorization);
        return {
          ...state,
          isLoading: false,
          token: Authorization,
          asEmployee: false,
        };
      })
      .addCase(loginEmployees.rejected, (state) => {
        return {
          ...state,
          isLoading: false,
          isShowPassword: false,
        };
      });
  },
});

export const { setUserData, showPassword, showConfPassword, logout, onChangeLogin } =
  authSlice.actions;
export default authSlice.reducer;

export const login = createAsyncThunk("auth/login", async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_CLIENT_API_URL}/login`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const loginEmployees = createAsyncThunk("loginEmployees", async (data: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CLIENT_API_URL}/login-employees`,
      data
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});
