import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface state {
  listContact: any[];
  editMode: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  showModal: boolean;
  modalType: number | null;
  id: string;
  editValue: {};
  detailValue: {};
  count: number;
  currentPage: number;
  totalItem: number;
}

const initialState: state = {
  listContact: [],
  editMode: false,
  isLoading: true,
  isDisabled: false,
  showModal: false,
  modalType: null,
  id: "",
  editValue: {},
  detailValue: {},
  count: 1,
  currentPage: 1,
  totalItem: 20,
};

const slice = createSlice({
  name: "merchants",
  initialState,
  reducers: {
    showPopupModal: (state) => {
      state.modalType = 1;
      state.showModal = !state.showModal;
      state.id = "";
      state.editMode = false;
      state.editValue = {};
    },
    showDetailModal: (state, { payload }) => {
      const findContact = state.listContact.find((prev) => prev.id === payload);
      state.modalType = 2;
      if (findContact !== undefined) {
        state.showModal = true;
        state.detailValue = findContact;
      }
      if (findContact === undefined) {
        state.showModal = false;
        state.detailValue = {};
      }
    },
    updatedContact: (state, { payload }) => {
      const findMerchant = state.listContact.find((data: any) => data.id === payload);
      state.modalType = 1;
      if (findMerchant !== undefined) {
        state.id = findMerchant.id;
        state.showModal = true;
        state.editMode = true;
        state.editValue = findMerchant;
      }

      if (findMerchant === undefined) {
        state.showModal = false;
        state.id = "";
        state.editValue = findMerchant;
      }

      state.editMode = findMerchant === undefined ? false : true;
    },
    handleChange: (state, { payload }) => {
      state.currentPage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllContact.fulfilled, (state, { payload }) => {
        const { data, count } = payload;
        return {
          ...state,
          isLoading: false,
          listContact: data,
          count,
        };
      })
      .addCase(getAllContact.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createContact.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(createContact.fulfilled, (state) => {
        state.isDisabled = false;
      })
      .addCase(createContact.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(editContact.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(editContact.fulfilled, (state, { meta: { arg } }) => {
        const { id, fname, lname, phoneNumber, email, keterangan } = arg;
        const index = state.listContact.findIndex((data: any) => data.id === id);
        const newValue: any = {
          id,
          fname: fname,
          lname: lname,
          phoneNumber: phoneNumber,
          email: email,
          keterangan: keterangan,
        };
        state.isDisabled = false;
        state.listContact[index] = newValue;
      })
      .addCase(editContact.rejected, (state) => {
        state.isDisabled = false;
      });
  },
});
export const { showPopupModal, handleChange, updatedContact, showDetailModal } = slice.actions;
export default slice.reducer;

export const getAllContact = createAsyncThunk(
  "getAllContact",
  async (data: { totalItem: number; currentPage: number }) => {
    try {
      const response = await axiosInstance.get(
        `/merchants?totalItem=${data.totalItem}&page=${data.currentPage}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const createContact = createAsyncThunk("createContact", async (data: any) => {
  try {
    const response = await axiosInstance.post(`/merchants`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const editContact = createAsyncThunk("editContact", async (data: any) => {
  try {
    const response = await axiosInstance.put(`/merchants`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const deleteContact = createAsyncThunk("deleteContact", async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/merchants/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});
