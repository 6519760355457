import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface State {
  listBank: any[];
  editMode: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  showModal: boolean;
  id: string;
  editValue: {};
}

const initialState: State = {
  listBank: [],
  editMode: false,
  isLoading: true,
  isDisabled: false,
  showModal: false,
  id: "",
  editValue: {},
};

const slice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    showPopupModal: (state: State) => {
      state.showModal = !state.showModal;
      state.id = "";
      state.editMode = false;
      state.editValue = {};
    },
    updatedBank: (state: State, { payload }) => {
      const [findRole] = state.listBank.filter((data: any) => data.id === payload);
      if (findRole !== undefined) {
        state.showModal = !state.showModal;
        state.id = findRole.id;
        state.editMode = true;
        state.editValue = findRole;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBank.fulfilled, (state, { payload }) => {
        const { data } = payload;
        return {
          ...state,
          isLoading: false,
          listBank: data,
        };
      })
      .addCase(getAllBank.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createBank.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(createBank.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.isDisabled = false;
        state.listBank = [...state.listBank, data];
      })
      .addCase(createBank.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(editBank.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(editBank.fulfilled, (state, { meta: { arg } }) => {
        const { name, accountName, id, rekeningNumber } = arg;
        const index = state.listBank.findIndex((data: any) => data.id === id);
        const newValue: any = {
          id,
          name,
          accountName,
          rekeningNumber,
        };
        state.isDisabled = false;
        state.listBank[index] = newValue;
      })
      .addCase(editBank.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(deleteBank.fulfilled, (state, action) => {
        const { arg } = action.meta;
        const newData = state.listBank.filter((data: any) => data.id !== arg);
        return {
          ...state,
          listBank: newData,
        };
      });
  },
});
export const { showPopupModal, updatedBank } = slice.actions;
export default slice.reducer;

export const getAllBank = createAsyncThunk("getAllBank", async () => {
  try {
    const response = await axiosInstance.get(`/bank`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const createBank = createAsyncThunk("createBank", async (data: any, _) => {
  try {
    const response = await axiosInstance.post(`/bank`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const editBank = createAsyncThunk("editBank", async (data: any) => {
  try {
    const response = await axiosInstance.put(`/bank`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const deleteBank = createAsyncThunk("deleteBank", async (id: string, _) => {
  try {
    const response = await axiosInstance.delete(`/bank/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});
