import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface State {
  listKomplek: any[];
  editMode: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  showModal: boolean;
  id: string;
  editValue: {};
}

const initialState: State = {
  listKomplek: [],
  editMode: false,
  isLoading: true,
  isDisabled: false,
  showModal: false,
  id: "",
  editValue: {},
};

const slice = createSlice({
  name: "komplek",
  initialState,
  reducers: {
    showPopupModal: (state) => {
      state.showModal = !state.showModal;
      state.id = "";
      state.editMode = false;
      state.editValue = {};
    },
    updateKomplek: (state, { payload }) => {
      const [findStatus] = state.listKomplek.filter((data: any) => data.id === payload);
      if (findStatus !== undefined) {
        state.showModal = !state.showModal;
        state.id = findStatus.id;
        state.editMode = true;
        state.editValue = findStatus;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllKomplek.fulfilled, (state, { payload }) => {
        const { data } = payload;
        return {
          ...state,
          isLoading: false,
          listKomplek: data,
        };
      })
      .addCase(getAllKomplek.rejected, (state) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(createKomplek.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(createKomplek.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.isDisabled = false;
        state.listKomplek = [...state.listKomplek, data];
      })
      .addCase(createKomplek.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(editKomplek.fulfilled, (state, { meta: { arg } }) => {
        const { id, name } = arg;
        const index = state.listKomplek.findIndex((data: any) => data.id === id);
        const newValue = { id, name };
        state.listKomplek[index] = newValue;
      })
      .addCase(deleteKomplek.fulfilled, (state, { meta }) => {
        const { arg } = meta;
        const newData = state.listKomplek.filter((data: any) => data.id !== arg);
        state.listKomplek = newData;
      });
  },
});
export const { showPopupModal, updateKomplek } = slice.actions;
export default slice.reducer;

export const getAllKomplek = createAsyncThunk("getAllKomplek", async () => {
  try {
    const response = await axiosInstance.get(`/komplek`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const createKomplek = createAsyncThunk("createKomplek", async (data: any) => {
  try {
    const response = await axiosInstance.post(`/komplek`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const editKomplek = createAsyncThunk("editKomplek", async (data: any) => {
  try {
    const response = await axiosInstance.put(`/komplek`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const deleteKomplek = createAsyncThunk("deleteKomplek", async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/komplek/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});
