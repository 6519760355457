import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface State {
  listDetailBilling: any[];
  editMode: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  showModal: boolean;
  id: string;
  editValue: {};
  amount: number;
}

const initialState: State = {
  listDetailBilling: [],
  editMode: false,
  isLoading: true,
  isDisabled: false,
  showModal: false,
  id: "",
  editValue: {},
  amount: 0,
};

const slice = createSlice({
  name: "detail billing",
  initialState,
  reducers: {
    showPopupModal: (state: State) => {
      state.showModal = !state.showModal;
      state.id = "";
      state.editMode = false;
      state.editValue = {};
      state.amount = 0;
    },
    updateDetailBilling: (state: State, action: PayloadAction<string>) => {
      const [findRole] = state.listDetailBilling.filter((data: any) => data.id === action.payload);
      if (Object.keys(findRole).length > 0) {
        state.showModal = !state.showModal;
        state.id = findRole.id;
        state.editMode = true;
        state.editValue = findRole;
        state.amount = findRole.amount;
      }
    },
    handleAmountInput: (state: State, action: PayloadAction<number>) => {
      state.amount = +action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDetailBilling.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(getAllDetailBilling.fulfilled, (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          isLoading: false,
          listDetailBilling: data,
        };
      })
      .addCase(getAllDetailBilling.rejected, (state) => {
        return {
          ...state,
          isLoading: false,
          listDetailBilling: [],
        };
      })
      .addCase(createDetailBilling.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(createDetailBilling.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.isDisabled = false;
        state.listDetailBilling = [...state.listDetailBilling, data];
      })
      .addCase(createDetailBilling.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(editDetailBilling.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(editDetailBilling.fulfilled, (state, action) => {
        state.isDisabled = false;

        const { title, amount, id } = action.meta.arg;
        const index = state.listDetailBilling.findIndex((data: any) => data.id === id);
        const newValue: any = { id, title, amount };
        state.listDetailBilling[index] = newValue;
      })
      .addCase(editDetailBilling.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(deleteDetailBilling.fulfilled, (state, action) => {
        const { arg } = action.meta;
        const newData = state.listDetailBilling.filter((data: any) => data.id !== arg);
        return {
          ...state,
          listDetailBilling: newData,
        };
      });
  },
});
export const { showPopupModal, updateDetailBilling, handleAmountInput } = slice.actions;
export default slice.reducer;

export const getAllDetailBilling = createAsyncThunk("getAllDetailBilling", async () => {
  try {
    const response = await axiosInstance.get(`/detail-billing`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const createDetailBilling = createAsyncThunk("createDetailBilling", async (data: any) => {
  try {
    const response = await axiosInstance.post(`/detail-billing`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const editDetailBilling = createAsyncThunk("editDetailBilling", async (data: any) => {
  try {
    const response = await axiosInstance.put(`/detail-billing`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const deleteDetailBilling = createAsyncThunk("deleteDetailBilling", async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/detail-billing/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});
