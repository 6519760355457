import { useState, useEffect } from "react";
import { List } from "@mui/material";
import { useLocation, matchPath } from "react-router-dom";
import SidebarMenuItem from "./item";
import menuItems, { routePath } from "./items";
import { styled } from "@mui/material/styles";
import { useAppSelector } from "src/redux/hooks";

export interface MenuItem {
  id?: string;
  link?: string;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

const SubMenuWrapper = styled(List)(
  ({ theme }) => `
    &.MuiList-root {
      padding: 0;

      .MuiList-root .MuiList-root .MuiListItem-root .MuiButton-root {
        font-weight: normal !important;
      }

      .MuiListItem-root {
        padding: 2px ${theme.spacing(2)};

        .MuiButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: 100%;
          justify-content: flex-start;
          font-size: ${theme.typography.pxToRem(13)};
          padding-top: ${theme.spacing(0.8)};
          padding-bottom: ${theme.spacing(0.8)};
          position: relative;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(4)};

            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(9)};
              font-weight: bold;
              text-transform: uppercase;
              color: ${theme.palette.primary.contrastText};
            }
          }

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            font-size: ${theme.typography.pxToRem(26)};
            margin-right: ${theme.spacing(1.5)};
            color: ${theme.sidebar.menuItemIconColor};
          }

          .MuiButton-endIcon {
            margin-left: auto;
            font-size: ${theme.typography.pxToRem(22)};
          }

          &.Mui-active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;
          line-height: 1;
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px ${theme.spacing(0)};

            .MuiButton-root {
              font-size: ${theme.typography.pxToRem(13)};
              padding: ${theme.spacing(0.5, 2, 0.5, 3.5)};

              &.Mui-active,
              &:hover {
                background-color: ${theme.sidebar.menuItemBg};
              }
            }
          }
        }
      }
    }
`
);

function sideBarMenu(key: any, exactMatch: boolean, item: MenuItem) {
  return (
    <SidebarMenuItem
      key={key}
      active={exactMatch}
      name={item.name}
      link={item.link}
      badge={item.badge}
    />
  );
}

function sideBarMenuWithChild(key: any, path: string, partialMatch: boolean, item: MenuItem) {
  return (
    <SidebarMenuItem
      key={key}
      active={partialMatch}
      open={partialMatch}
      name={item.name}
      link={item.link}
      badge={item.badge}
    >
      <SubMenuWrapper>
        {item.items!.map((data) => (
          <SidebarMenuItem
            key={data.id}
            active={data.link === path}
            name={data.name}
            link={data.link}
          />
        ))}
      </SubMenuWrapper>
    </SidebarMenuItem>
  );
}

function menu(listModule: any[], path: string) {
  return listModule.map((item) => {
    const key = item.name;

    const exactMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: true,
          },
          path
        )
      : false;
    if (item.items) {
      const parentPath = `/${path.split("/")[1]}`;
      const partialMatch = item.link
        ? !!matchPath(
            {
              path: item.link,
              end: false,
            },
            parentPath
          )
        : false;
      return sideBarMenuWithChild(key, path, partialMatch, item);
    }
    return sideBarMenu(key, exactMatch, item);
  });
}

function SidebarMenu() {
  const { pathname } = useLocation();
  const { listModuleAccess = [] } = useAppSelector((state) => state.auth);
  const [module, setModule] = useState<any[]>([]);

  useEffect(() => {
    handleList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleList = () => {
    let arrMenu: any = [];
    let listMenu: any[] = [
      { id: routePath.DASHBOARD.id, name: "Dashboard", link: routePath.DASHBOARD.pathName },
    ];

    for (const { id, items, link, name } of menuItems) {
      if (items !== undefined) {
        // has child
        arrMenu.push({ id: "", name, link, items });
      } else {
        // single menu
        arrMenu.push({ id: id === undefined ? "" : id, name, link });
      }
    }

    for (const { moduleId, canRead } of listModuleAccess) {
      for (const { id, name, items, link } of arrMenu) {
        // checking if menu have child
        if (items !== undefined) {
          for (let i = 0; i < items.length; i++) {
            const val = items[i];
            // check if have permission for access child
            if (val.id === moduleId && canRead === 1) {
              const index = listMenu.findIndex((prev) => prev.name === name);
              if (index === -1) {
                // push if module not exists in array
                listMenu.push({
                  id: "",
                  name,
                  link,
                  items: [{ id: val.id, name: val.name, link: val.link }],
                });
              } else {
                // push if module already exists in array
                listMenu[index].items.push({ id: val.id, name: val.name, link: val.link });
              }
            }
          }
        }

        // check if have permission for access parent
        if (moduleId === id && canRead === 1) listMenu.push({ id, name, link });
      }
    }

    setModule((prev) => (prev = listMenu));
  };
  return <SubMenuWrapper>{menu(module, pathname)}</SubMenuWrapper>;
}

export default SidebarMenu;
