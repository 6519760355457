import Swal, { SweetAlertPosition, SweetAlertIcon } from "sweetalert2";

type PropsConfirm = {
  title: string;
  text: string;
  icon: "warning" | "success" | "error";
  confirmButtonText?: string;
  cancelButtonText?: string;
};

export const SwalConfirmModal = ({
  title = "",
  text = "",
  icon = "warning",
  confirmButtonText = "",
  cancelButtonText = "Tidak",
}: PropsConfirm) => {
  return Swal.fire({
    title,
    text,
    icon,
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText,
    cancelButtonText,
  });
};

export const SwalAlertModal = (
  title: string,
  text: string,
  status: "info" | "success" | "warning" | "question" | "error"
) => Swal.fire(title, text, status);

type SwalCornerProps = {
  position?: SweetAlertPosition | undefined;
  icon?: SweetAlertIcon | undefined;
  title: string;
  text: string;
};

export const SwalAlertCornerTop = ({
  position = "top-end",
  icon = "warning",
  title,
  text,
}: SwalCornerProps) =>
  Swal.fire({
    position,
    icon,
    title,
    text,
    showConfirmButton: false,
    timer: 2000,
  });
