import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface State {
  listBillinghistory: any[];
  isLoading: boolean;
  showModal: boolean;
  notaImage: string;
  count: number;
  homeDetailId: string;
  currentPage: number;
  totalItem: number;
}

const initialState: State = {
  listBillinghistory: [],
  isLoading: false,
  showModal: false,
  notaImage: "",
  homeDetailId: "",
  count: 1,
  currentPage: 1,
  totalItem: 20,
};

const slice = createSlice({
  name: "billing history",
  initialState,
  reducers: {
    showPopupModal: (state: State) => {
      state.showModal = !state.showModal;
      state.notaImage = "";
      state.homeDetailId = "";
    },
    handleChange: (state: State, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    showImageModal: (state: State, { payload }) => {
      const [findPayment] = state.listBillinghistory.filter((prev) => prev.id === payload);

      if (Object.keys(findPayment).length > 0) {
        state.homeDetailId = findPayment.id;
        state.notaImage = process.env.REACT_APP_CLIENT_URL + findPayment.image;
        state.showModal = !state.showModal;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllBillingHistory.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    builder.addCase(getAllBillingHistory.fulfilled, (state, { payload }) => {
      const { data } = payload;
      return {
        ...state,
        isLoading: false,
        listBillinghistory: data,
      };
    });
    builder.addCase(getAllBillingHistory.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        listBillinghistory: [],
      };
    });
  },
});
export const { handleChange, showPopupModal, showImageModal } = slice.actions;
export default slice.reducer;

export const getAllBillingHistory = createAsyncThunk(
  "getAllBillingHistory",
  async (data: { totalItem: number; currentPage: number }) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_CLIENT_API_URL}/billing-history?totalItem=${data.totalItem}&page=${data.currentPage}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const approvePayment = createAsyncThunk(
  "approvePayment",
  async (value: { id: number; homeDetailId: number }) => {
    try {
      const response = await axiosInstance.put(
        `${process.env.REACT_APP_CLIENT_API_URL}/approved-payment/?id=${value.id}&home=${value.homeDetailId}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const rejectPayment = createAsyncThunk(
  "approvePayment",
  async (value: { id: number; homeDetailId: number }) => {
    try {
      const response = await axiosInstance.put(
        `${process.env.REACT_APP_CLIENT_API_URL}/rejected-payment?id=${value.id}&home=${value.homeDetailId}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);
