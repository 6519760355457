import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";
import { saveAs } from "file-saver";

interface State {
  listVisitor: any[];
  listUnkVisitor: any[];
  image: string;
  showModal: boolean;
  isEditable: boolean;
  isLoading: boolean;
  startDate: string | null | undefined;
  endDate: string | null | undefined;
  visitorCount: number;
  visitorCurrentPage: number;
  visitorTotalItem: number;
  visitorUnkCount: number;
  visitorUnkCurrentPage: number;
  visitorUnkTotalItem: number;
  params: string;
}

const initialState: State = {
  listVisitor: [],
  listUnkVisitor: [],
  showModal: false,
  isEditable: true,
  isLoading: true,
  image: "",
  startDate: Date(),
  endDate: null,
  visitorCount: 1,
  visitorCurrentPage: 1,
  visitorTotalItem: 15,
  visitorUnkCount: 1,
  visitorUnkCurrentPage: 1,
  visitorUnkTotalItem: 15,

  params: "",
};

const slice = createSlice({
  name: "gate history",
  initialState,
  reducers: {
    handleChangeVisitor: (state, { payload }) => {
      state.visitorUnkCurrentPage = payload;
    },
    handleChangeUnkVisitor: (state, { payload }) => {
      state.visitorUnkCurrentPage = payload;
    },
    selectDate: (state, { payload }: PayloadAction<{ type: string; date: string | null }>) => {
      const { date, type } = payload;
      if (type === "startDate") {
        state.endDate = null;
        state.startDate = date;
      } else {
        state.endDate = date;
      }
    },
    resetDate: (state, { payload }) => {
      state.startDate = Date();
      state.endDate = null;
      state.params = "";

      if (payload === "UnkVisitor") {
        state.visitorUnkCurrentPage = 1;
      } else {
        state.visitorCurrentPage = 1;
      }
    },
    showPopupModal: (state) => {
      return { ...state, image: "", showModal: !state.showModal };
    },
    showImageModal: (state, { payload }) => {
      const [findData] = state.listUnkVisitor.filter((prev) => prev.id === payload);

      if (findData !== undefined) {
        state.image = process.env.REACT_APP_CLIENT_URL + findData.foto;
        state.showModal = !state.showModal;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllVisitor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllVisitor.fulfilled, (state, { payload, meta }) => {
        const { data, count } = payload;
        return {
          ...state,
          isLoading: false,
          listVisitor: data,
          visitorCount: count,
          params: meta.arg,
        };
      })
      .addCase(getAllVisitor.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAllUnknownVisitor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllUnknownVisitor.fulfilled, (state, { payload, meta }) => {
        const { data, count } = payload;
        return {
          ...state,
          isLoading: false,
          listUnkVisitor: data,
          visitorUnkCount: count,
          params: meta.arg,
        };
      })
      .addCase(getAllUnknownVisitor.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
export const {
  handleChangeVisitor,
  handleChangeUnkVisitor,
  showPopupModal,
  showImageModal,
  selectDate,
  resetDate,
} = slice.actions;
export default slice.reducer;

export const getAllVisitor = createAsyncThunk("getAllVisitor", async (params: string) => {
  try {
    const response = await axiosInstance.get(`/visitor?${params}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const getAllUnknownVisitor = createAsyncThunk(
  "getAllUnknownVisitor",
  async (params: string) => {
    try {
      const response = await axiosInstance.get(`/unknown-visitor?${params}`);
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const genereateUnkPdf = createAsyncThunk("genereateUnkPdf", async (params: string) => {
  try {
    const { data } = await axiosInstance.get(`/unknown-visitor-pdf?${params}`, {
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(data);
    saveAs(url, "report_unk_visitor.pdf");
  } catch (error: any) {
    throw error.response.data;
  }
});

export const genereatePdf = createAsyncThunk("genereatePdf", async (params: string) => {
  try {
    const { data } = await axiosInstance.get(`/visitor-pdf?${params}`, {
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(data);
    saveAs(url, "repor_visitor.pdf");
  } catch (error: any) {
    throw error.response.data;
  }
});
