import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface state {
  listService: any[];
  editMode: boolean;
  isDisabled: boolean;
  isServiceLoading: boolean;
  amount: number;
  id: number;
  serviceId: number;
  onlyThisService: boolean;
}

const initialState: state = {
  listService: [],
  editMode: false,
  isDisabled: false,
  isServiceLoading: false,
  amount: 0,
  id: NaN,
  serviceId: NaN,
  onlyThisService: false,
};

const slice = createSlice({
  name: "warga",
  initialState,
  reducers: {
    handleAmountInput: (state, action: PayloadAction<number>) => {
      state.amount = +action.payload;
    },
    handleUpdateService: (state, { payload }) => {
      const { id, amount } = payload;
      state.serviceId = id;
      state.amount = amount;
      state.editMode = true;
    },
    checkBox: (state) => {
      state.onlyThisService = !state.onlyThisService;
    },
    cleanService: (state) => {
      state.onlyThisService = false;
      state.listService = [];
      state.id = NaN;
      state.serviceId = NaN;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createService.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(createService.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.amount = 0;
        state.isDisabled = false;

        state.listService = [...state.listService, ...data.listService];
      })
      .addCase(createService.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(getServiceById.pending, (state) => {
        state.isServiceLoading = true;
      })
      .addCase(getServiceById.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.isServiceLoading = false;
        if (data.id !== null) {
          state.onlyThisService = data.onlyThisService === 1 ? true : false;
          state.listService = data.listService;
        }
      })
      .addCase(getServiceById.rejected, (state) => {
        state.isServiceLoading = false;
        state.listService = [];
      })
      .addCase(deleteService.fulfilled, (state, { meta }) => {
        const { arg } = meta;
        const newData = state.listService.filter((data: any) => data.id !== arg);
        return {
          ...state,
          listService: newData,
        };
      })
      .addCase(editServiceById.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(editServiceById.fulfilled, (state, { meta: { arg } }) => {
        const { value, id } = arg;
        const index = state.listService.findIndex((data: any) => data.id === id);
        const newValue: any = {
          id,
          title: value.title,
          amount: +value.amount,
        };
        state.isDisabled = false;
        state.listService[index] = newValue;
        state.amount = 0;
        state.editMode = false;
      })
      .addCase(editServiceById.rejected, (state) => {
        state.isDisabled = false;
      });
  },
});
export const { handleUpdateService, handleAmountInput, checkBox, cleanService } = slice.actions;
export default slice.reducer;

export const createService = createAsyncThunk(
  "createService",
  async (data: { homeDetailId: number; value: any }) => {
    try {
      const response = await axiosInstance.post(`/home-service/${data.homeDetailId}`, data.value);
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const getServiceById = createAsyncThunk("getServiceById", async (homeDetailId: number) => {
  try {
    const response = await axiosInstance.get(`/home-service/${homeDetailId}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const editServiceById = createAsyncThunk(
  "editServiceById",
  async (data: { id: number; value: any }) => {
    try {
      const response = await axiosInstance.put(`/home-service/${data.id}`, data.value);
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const deleteService = createAsyncThunk("deleteService", async (homeDetailId: number) => {
  try {
    const response = await axiosInstance.delete(`/home-service/${homeDetailId}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const updateCheckBox = createAsyncThunk(
  "updateCheckBox",
  async (value: { home: number; onlyThisService: boolean }) => {
    try {
      const response = await axiosInstance.post(
        `/use-service?home=${value.home}&onlyThisService=${value.onlyThisService}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);
