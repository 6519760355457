import { MenuItem } from "./index";

export const routePath = {
  // AUTH
  SIGNIN: { id: "", pathName: "/signin" },

  //DASHBOARD
  DASHBOARD: { id: "", pathName: "/dashboard" },

  // Announcement
  ANNOUNCEMENT: { id: "2e07d567-e5c6-4f42-92f6-f515b9281e86", pathName: "/announcement" },

  // Management Resident
  KOMPLEK: { id: "059cfd9a-208a-4bff-bcc7-9f28bc72880f", pathName: "/management-resident/komplek" },
  WARGA: { id: "c0734c1c-b4f4-45a4-8dca-7a3cc02aebb8", pathName: "/management-resident/warga" },

  // Billing/IPL
  TUNGGAKAN: { id: "d941eaea-efd9-4555-823b-1289a0954204", pathName: "/billing-ipl/tunggakan" },
  TUNGGAKAN_DETAIL: {
    id: "d941eaea-efd9-4555-823b-1289a0954204",
    pathName: "/billing-ipl/tunggakan/:homeDetailId",
  },
  BILLING_PAYMENT: {
    id: "afbb3257-c23c-4645-a361-a93cc87d4121",
    pathName: "/billing-ipl/billing-payment",
  },
  DETAIL_BILLING: {
    id: "2bba3f27-7cd2-47d0-a933-62ac4d74eeba",
    pathName: "/billing-ipl/detail-billing",
  },

  // Management Team
  ROLES: { id: "e114b4a4-17d8-4df8-b484-c913c93e36d1", pathName: "/management-team/roles" },
  EMPLOYEES: { id: "c9c75d5f-7270-4f3e-b609-78b77bee3ee6", pathName: "/management-team/employees" },
  PERMISSION: {
    id: "cbcfa570-08da-4135-bb73-77e62db23651",
    pathName: "/management-team/permission",
  },
  ROLE_PERMISSION: {
    id: "cbcfa570-08da-4135-bb73-77e62db23651",
    pathName: "/management-team/permission/role-permission/:id",
  },
  SECURITY: {
    id: "a639dc55-8f0f-4bb3-afab-5ecc96f0f541",
    pathName: "/management-team/permission/security",
  },

  // Helpdesk
  COMPLAINT: { id: "c38f9048-aa50-4c8a-b657-9ac8b19e80fb", pathName: "/helpdek/complaint" },
  QUESTION: {
    id: "b30c27a6-28b3-4419-8cf6-c9b2a40b4280",
    pathName: "/helpdek/question",
  },

  // Merchants
  MERCHANTS: {
    id: "49727f9c-5994-42c8-8948-8b50ceb96118",
    pathName: "/management-merchant",
  },

  // Gate History
  VISITOR: {
    id: "6fa016b2-9cd5-40bc-abe3-b7c6e53ac710",
    pathName: "/gate-history/visitor",
  },
  UNKNOW_VISITOR: {
    id: "e5610969-7b9e-45c1-b165-fefac981c104",
    pathName: "/gate-history/unknown-visitor",
  },

  // Emergency
  EMERGENCY_CONTACT: {
    id: "e6c045c0-0dfa-4071-9eec-8144eb61c026",
    pathName: "/emergency/contact",
  },
  EMERGENCY_HISTORY: { id: "90bcab21-2878-4ef1-bb6d-f845eca0e6fd", pathName: "/emergency/history" },

  // Bank
  BANK: { id: "2dff4e05-45da-48da-b767-23462718a773", pathName: "/bank" },

  // Service
  SERVICE: { id: "143243c0-fa4c-4c32-99d3-519b7838eaf5", pathName: "/services" },
  SERVICE_REQUEST: {
    id: "143243c0-fa4c-4c32-99d3-519b7838eaf5",
    pathName: "/services/request/:serviceId",
  },

  // 404
  NOT_FOUND: { id: "", pathName: "/not-found" },
};

const menuItems: MenuItem[] = [
  {
    name: "Dashboard",
    link: routePath.DASHBOARD.pathName,
  },
  {
    id: routePath.ANNOUNCEMENT.id,
    name: "Announcement",
    link: routePath.ANNOUNCEMENT.pathName,
  },
  {
    name: "Management Resident",
    link: "/management-resident",
    items: [
      {
        id: routePath.KOMPLEK.id,
        name: "Komplek",
        link: routePath.KOMPLEK.pathName,
      },
      {
        id: routePath.WARGA.id,
        name: "Warga",
        link: routePath.WARGA.pathName,
      },
    ],
  },
  {
    name: "Billing/IPL",
    link: "/billing-ipl",
    items: [
      {
        id: routePath.TUNGGAKAN.id,
        name: "Tunggakan",
        link: routePath.TUNGGAKAN.pathName,
      },
      {
        id: routePath.BILLING_PAYMENT.id,
        name: "Billing Payment",
        link: routePath.BILLING_PAYMENT.pathName,
      },
      {
        id: routePath.DETAIL_BILLING.id,
        name: "Detail Billing",
        link: routePath.DETAIL_BILLING.pathName,
      },
    ],
  },
  {
    name: "Management Team",
    link: "/management-team",
    items: [
      {
        id: routePath.ROLES.id,
        name: "Roles",
        link: routePath.ROLES.pathName,
      },
      {
        id: routePath.EMPLOYEES.id,
        name: "Employees",
        link: routePath.EMPLOYEES.pathName,
      },
      {
        id: routePath.PERMISSION.id,
        name: "Permissions",
        link: routePath.PERMISSION.pathName,
      },
      {
        id: routePath.SECURITY.id,
        name: "Security",
        link: routePath.SECURITY.pathName,
      },
    ],
  },
  {
    name: "Helpdesk",
    link: "/helpdesk",
    items: [
      {
        id: routePath.COMPLAINT.id,
        name: "Complaints",
        link: routePath.COMPLAINT.pathName,
      },
      {
        id: routePath.QUESTION.id,
        name: "Question",
        link: routePath.QUESTION.pathName,
      },
    ],
  },
  {
    id: routePath.MERCHANTS.id,
    name: "Merchants",
    link: routePath.MERCHANTS.pathName,
  },
  {
    name: "Gate History",
    link: "/gate-history",
    items: [
      {
        id: routePath.VISITOR.id,
        name: "Visitor",
        link: routePath.VISITOR.pathName,
      },
      {
        id: routePath.UNKNOW_VISITOR.id,
        name: "Unknown Visitor",
        link: routePath.UNKNOW_VISITOR.pathName,
      },
    ],
  },
  {
    name: "Emergency",
    link: "/emergency",
    items: [
      {
        id: routePath.EMERGENCY_CONTACT.id,
        name: "Contact",
        link: routePath.EMERGENCY_CONTACT.pathName,
      },
      {
        id: routePath.EMERGENCY_HISTORY.id,
        name: "History",
        link: routePath.EMERGENCY_HISTORY.pathName,
      },
    ],
  },
  {
    id: routePath.BANK.id,
    name: "Bank",
    link: routePath.BANK.pathName,
  },
  {
    id: routePath.SERVICE.id,
    name: "Services",
    link: routePath.SERVICE.pathName,
  },
];

export default menuItems;
