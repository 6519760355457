import { Routes, Route } from "react-router-dom";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import LoginPage from "src/content/auth/signin_page";
import AuthModule from "src/Auth_module";
import Status404 from "src/content/Status404";
import ThemeProvider from "./theme/ThemeProvider";
import { CssBaseline } from "@mui/material";

const App = () => {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <Routes>
          <Route path="/*" element={<AuthModule />} caseSensitive={true} />
          <Route path="/signin" element={<LoginPage />} caseSensitive={true} />
          <Route path="*" element={<Status404 />} caseSensitive={true} />
        </Routes>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
export default App;
