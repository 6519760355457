import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface state {
  listEmergencyHistory: any[];
  isLoading: boolean;
  count: number;
  currentPage: number;
  totalItem: number;
}

const initialState: state = {
  listEmergencyHistory: [],
  isLoading: true,
  count: 1,
  currentPage: 1,
  totalItem: 20,
};

const slice = createSlice({
  name: "emergency history",
  initialState,
  reducers: {
    handleChange: (state, { payload }) => {
      state.currentPage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllEmergencyhistory.fulfilled, (state, { payload }) => {
        const { data, count } = payload;
        return {
          ...state,
          isLoading: false,
          listEmergencyHistory: data,
          count,
        };
      })
      .addCase(getAllEmergencyhistory.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
export const { handleChange } = slice.actions;
export default slice.reducer;

export const getAllEmergencyhistory = createAsyncThunk(
  "getAllEmergencyhistory",
  async (data: { totalItem: number; currentPage: number }) => {
    try {
      const response = await axiosInstance.get(
        `/emergency-history?totalItem=${data.totalItem}&page=${data.currentPage}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);
