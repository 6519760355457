import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";

interface state {
  isLoading: boolean;
  qrValue: {
    code: string;
    resident: string;
    residentName: string;
  };
}

const initialState: state = {
  isLoading: false,
  qrValue: {
    code: "",
    resident: "",
    residentName: "",
  },
};

const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    getDecodeToken: (state, { payload }) => {
      const splitToken = payload.split(" ");
      const decodeToken: any = jwt_decode(splitToken[1]);
      const { uuid, username, name, residentId, resident, residentName } = decodeToken;
      state.qrValue = {
        code: residentId ?? uuid,
        resident: resident ?? username,
        residentName: residentName ?? name,
      };
    },
  },
});

export const { getDecodeToken } = slice.actions;
export default slice.reducer;
