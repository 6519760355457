import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface state {
  listEmergencyCall: any[];
  editMode: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  showModal: boolean;
  id: string;
  editValue: {};
}

const initialState: state = {
  listEmergencyCall: [],
  editMode: false,
  isLoading: true,
  isDisabled: false,
  showModal: false,
  id: "",
  editValue: {},
};

const slice = createSlice({
  name: "emergency contact",
  initialState,
  reducers: {
    showPopupModal: (state) => {
      state.showModal = !state.showModal;
      state.id = "";
      state.editMode = false;
      state.editValue = {};
    },
    updateEmergencyCall: (state, { payload }) => {
      const [findRole] = state.listEmergencyCall.filter((data: any) => data.id === payload);
      if (findRole !== undefined) {
        state.showModal = !state.showModal;
        state.id = findRole.id;
        state.editMode = true;
        state.editValue = findRole;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllEmergencyCall.fulfilled, (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          isLoading: false,
          listEmergencyCall: data,
        };
      })
      .addCase(getAllEmergencyCall.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createEmergencyCall.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(createEmergencyCall.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.isDisabled = false;
        state.listEmergencyCall = [...state.listEmergencyCall, data];
      })
      .addCase(createEmergencyCall.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(editEmergencyCall.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(editEmergencyCall.fulfilled, (state, { meta: { arg } }) => {
        const { title, phoneNumber, id } = arg;
        const index = state.listEmergencyCall.findIndex((data: any) => data.id === id);
        const newValue: any = {
          id,
          title: title,
          phoneNumber: phoneNumber,
        };
        state.isDisabled = false;
        state.listEmergencyCall[index] = newValue;
      })
      .addCase(editEmergencyCall.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(deleteEmergencyCall.fulfilled, (state, action) => {
        const { arg } = action.meta;
        const newData = state.listEmergencyCall.filter((data: any) => data.id !== arg);
        return {
          ...state,
          listEmergencyCall: newData,
        };
      });
  },
});
export const { showPopupModal, updateEmergencyCall } = slice.actions;
export default slice.reducer;

export const getAllEmergencyCall = createAsyncThunk("getAllEmergencyCall", async () => {
  try {
    const response = await axiosInstance.get(`/emergency-call`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const createEmergencyCall = createAsyncThunk("createEmergencyCall", async (data: any, _) => {
  try {
    const response = await axiosInstance.post(`/emergency-call`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const editEmergencyCall = createAsyncThunk("editEmergencyCall", async (data: any) => {
  try {
    const response = await axiosInstance.put(`/emergency-call`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const deleteEmergencyCall = createAsyncThunk("deleteEmergencyCall", async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/emergency-call/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});
