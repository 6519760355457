import authReducer from "./auth"; // auth

import dashboardReducer from "./slices/dashboard";
import announceReducer from "./slices/announcement";
import komplekReducer from "./slices/komplek";
import wargaReducer from "./slices/warga";
import homeServiceReducer from "./slices/home-service";
import tunggakanReducer from "./slices/tunggakan";
import billingHistoryReducer from "./slices/billing-payment";
import billingDetailReducer from "./slices/billing-detail";

import bankReducer from "./slices/bank";
import questionReducer from "./slices/question";
import complaintReducer from "./slices/complaint";
import merchantsReducer from "./slices/merchants";
import rolesReducer from "./slices/roles";
import employeesReducer from "./slices/employees";
import gateHistoryReducer from "./slices/gate-history";
import permissionsReducer from "./slices/permissions";
import securityReducer from "./slices/security";
import emergencyContactReducer from "./slices/emergency-contact";
import emergencyHistoryReducer from "./slices/emergency-history";
import clientService from "./slices/services";

export const reducer = {
  auth: authReducer,
  dashboard: dashboardReducer,

  announcement: announceReducer,

  komplek: komplekReducer,
  warga: wargaReducer,
  homeService: homeServiceReducer,

  tunggakan: tunggakanReducer,
  billingHistory: billingHistoryReducer,
  billingDetail: billingDetailReducer,

  roles: rolesReducer,
  employees: employeesReducer,
  security: securityReducer,
  permissions: permissionsReducer,

  question: questionReducer,
  complaint: complaintReducer,

  merchants: merchantsReducer,

  emergencyContact: emergencyContactReducer,
  emergencyHistory: emergencyHistoryReducer,

  gateHistory: gateHistoryReducer,
  bank: bankReducer,
  service: clientService,
};
