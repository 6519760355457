import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface State {
  listServices: any[];
  listRequestServices: any[];
  isLoading: boolean;
  isDisabled: boolean;
  showModal: boolean;
  editValue: object;
  serviceName: string;
  id: string;
  rescheduleId: string;
  date: string;
  rescheduleDate: string;
  isCheck: boolean;
  amount: number;
}

const initialState: State = {
  listServices: [],
  listRequestServices: [],
  isLoading: false,
  isDisabled: false,
  showModal: false,
  editValue: {},
  serviceName: "",
  id: "",
  rescheduleId: "",
  date: Date(),
  rescheduleDate: Date(),
  isCheck: true,
  amount: 0,
};

const slice = createSlice({
  name: "client services",
  initialState,
  reducers: {
    showPopupModal: (state: State) => {
      state.showModal = !state.showModal;
      state.id = "";
      state.isCheck = true;
      state.amount = 0;
      state.editValue = {};
    },
    updatedService: (state: State, action: PayloadAction<string>) => {
      const findService = state.listServices.find((data: any) => data.id === action.payload);
      if (findService !== undefined) {
        state.editValue = findService;
        state.showModal = !state.showModal;
        state.id = findService.id;
        state.amount = +findService.price;
        state.isCheck = +findService.isServiceActive === 1 ? true : false;
      }
    },
    handleAmountInput: (state: State, action: PayloadAction<number>) => {
      state.amount = +action.payload;
    },
    onCheckHandler: (state: State) => {
      state.isCheck = !state.isCheck;
    },
    resetDate: (state: State) => {
      state.date = Date();
    },
    reschedule: (state: State, action: PayloadAction<string>) => {
      if (action.payload !== "") {
        const findService = state.listRequestServices.find((prev) => prev.id === action.payload);

        if (findService !== undefined) {
          state.showModal = !state.showModal;
          state.rescheduleId = findService.id;
          state.rescheduleDate = findService.dateRequest;
        }
      } else {
        state.showModal = !state.showModal;
      }
    },
    rescheduleDateValue: (state: State, action: PayloadAction<string>) => {
      state.rescheduleDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllServices.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(getAllServices.fulfilled, (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          isLoading: false,
          listServices: data,
        };
      })
      .addCase(getAllServices.rejected, (state) => {
        return {
          ...state,
          isLoading: false,
          listServices: [],
        };
      })
      .addCase(modifyService.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(modifyService.fulfilled, (state, action) => {
        const { data } = action.payload;
        const index = state.listServices.findIndex((prev) => prev.id === data.id);
        state.isDisabled = false;
        state.listServices[index] = data;
      })
      .addCase(modifyService.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(getRequestServices.pending, (state, action) => {
        const { date } = action.meta.arg;

        state.isLoading = true;
        state.date = date;
      })
      .addCase(getRequestServices.fulfilled, (state, action) => {
        const { serviceName, data } = action.payload;
        return {
          ...state,
          isLoading: false,
          serviceName: serviceName,
          listRequestServices: data,
        };
      })
      .addCase(getRequestServices.rejected, (state) => {
        return {
          ...state,
          isLoading: false,
          listRequestServices: [],
        };
      })
      .addCase(finishServices.fulfilled, (state, action) => {
        const id = action.meta.arg;
        const index = state.listRequestServices.findIndex((prev) => prev.id === id);
        const [findData] = state.listRequestServices.filter((prev) => prev.id === id);
        const newData = {
          ...findData,
          isFinish: 1,
        };
        state.listRequestServices[index] = newData;
      })
      .addCase(cancelServices.fulfilled, (state, action) => {
        const id = action.meta.arg;
        const index = state.listRequestServices.findIndex((prev) => prev.id === id);
        const [findData] = state.listRequestServices.filter((prev) => prev.id === id);
        const newData = {
          ...findData,
          isCancel: 1,
        };
        state.listRequestServices[index] = newData;
      })
      .addCase(rescheduleServices.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(rescheduleServices.fulfilled, (state, action) => {
        const { value, id } = action.meta.arg;
        const index = state.listRequestServices.findIndex((prev) => prev.id === id);
        const [findData] = state.listRequestServices.filter((prev) => prev.id === id);
        const newData = {
          ...findData,
          dateRequest: value.rescheduleDate,
        };
        state.isDisabled = false;
        state.listRequestServices[index] = newData;
      })
      .addCase(rescheduleServices.rejected, (state) => {
        state.isDisabled = false;
      });
  },
});
export const {
  showPopupModal,
  updatedService,
  handleAmountInput,
  onCheckHandler,
  resetDate,
  reschedule,
  rescheduleDateValue,
} = slice.actions;
export default slice.reducer;

export const getAllServices = createAsyncThunk("getAllServices", async () => {
  try {
    const response = await axiosInstance.get(`/services`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const modifyService = createAsyncThunk("modifyService", async (data: any, _) => {
  try {
    const response = await axiosInstance.post(`/services-modify`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const getRequestServices = createAsyncThunk(
  "getRequestServices",
  async (data: { serviceId: string; date: string }) => {
    try {
      const response = await axiosInstance.get(
        `/services-request?serviceId=${data.serviceId}&date=${data.date}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const cancelServices = createAsyncThunk("cancelServices", async (id: string) => {
  try {
    const response = await axiosInstance.put(`/services-request-cancel/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const finishServices = createAsyncThunk("finishServices", async (id: string) => {
  try {
    const response = await axiosInstance.put(`/services-request-finish/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const rescheduleServices = createAsyncThunk(
  "rescheduleServices",
  async (data: { id: string; value: any }) => {
    try {
      const response = await axiosInstance.put(
        `/services-request-reschedule/${data.id}`,
        data.value
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);
