import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface state {
  listKomplek: any[];
  listWarga: any[];
  listFamily: any[];
  editMode: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  isServiceLoading: boolean;
  isFamilyLoading: boolean;
  showModal: boolean;
  modalDisplay: number | null;
  id: string;
  homeDetailId: number;
  editValue: {};
  isShowPassword: boolean;
  isShowConfPassword: boolean;
  detailWarga: {
    fname: string;
    lname: string;
    phoneNumber: string;
    nomorkk: string;
    address: string;
    dateOfBirth: Date | null;
    namaKomplek: string;
    email: string;
    religion: string;
    dueDate: number;
    lastDatePayment: Date | null;
    kkImage: string;
    ktpImage: string;
  };
  kk: {
    filename: any;
    filevalue: any;
  };
  ktp: {
    filename: any;
    filevalue: any;
  };
  lastDatePayment: string;
  dateOfBirth: string;
  count: number;
  currentPage: number;
  totalItem: number;
}

const initialState: state = {
  listKomplek: [],
  listWarga: [],
  listFamily: [],
  editMode: false,
  isLoading: true,
  isDisabled: false,
  homeDetailId: NaN,
  isServiceLoading: false,
  isFamilyLoading: true,
  showModal: false,
  modalDisplay: null,
  id: "",
  editValue: {},
  detailWarga: {
    fname: "",
    lname: "",
    phoneNumber: "",
    nomorkk: "",
    address: "",
    dateOfBirth: null,
    namaKomplek: "",
    email: "",
    religion: "",
    dueDate: 0,
    lastDatePayment: null,
    kkImage: "",
    ktpImage: "",
  },
  ktp: {
    filename: null,
    filevalue: null,
  },
  kk: {
    filename: null,
    filevalue: null,
  },
  lastDatePayment: Date(),
  dateOfBirth: Date(),
  isShowPassword: false,
  isShowConfPassword: false,
  count: 1,
  currentPage: 1,
  totalItem: 20,
};

const slice = createSlice({
  name: "warga",
  initialState,
  reducers: {
    showPopupModal: (state, { payload }) => {
      state.modalDisplay = payload;
      state.showModal = !state.showModal;
      state.id = "";
      state.editMode = false;
      state.editValue = {};
      state.ktp = {
        filename: null,
        filevalue: null,
      };
      state.kk = {
        filename: null,
        filevalue: null,
      };
      state.homeDetailId = NaN;
      state.lastDatePayment = Date();
    },
    showDetailWarga: (state, { payload }) => {
      const findWarga = state.listWarga.find((prev) => prev.id === payload);
      if (findWarga !== undefined) {
        state.modalDisplay = 2;
        state.showModal = true;
        state.homeDetailId = findWarga.homeDetailId;
        state.detailWarga = {
          fname: findWarga.fname,
          lname: findWarga.lname,
          phoneNumber: findWarga.phoneNumber,
          nomorkk: findWarga.nomorkk,
          address: findWarga.address,
          dateOfBirth: findWarga.dateOfBirth,
          namaKomplek: findWarga.namaKomplek,
          email: findWarga.email ?? "",
          religion: findWarga.religion,
          dueDate: findWarga.dueDate,
          lastDatePayment: new Date(findWarga.lastDatePayment),
          kkImage: findWarga.kkImage,
          ktpImage: findWarga.ktpImage,
        };
      }

      if (payload === undefined) {
        state.showModal = false;
        state.modalDisplay = null;

        state.detailWarga = {
          fname: "",
          lname: "",
          phoneNumber: "",
          nomorkk: "",
          address: "",
          dateOfBirth: null,
          namaKomplek: "",
          email: "",
          religion: "",
          dueDate: 0,
          lastDatePayment: null,
          kkImage: "",
          ktpImage: "",
        };
      }
    },
    showServicePopModal: (state, { payload }) => {
      state.modalDisplay = 3;
      state.homeDetailId = payload === undefined ? NaN : payload;

      if (payload !== undefined) {
        state.showModal = true;
      }

      if (payload === undefined) {
        state.showModal = false;
        state.editMode = false;
      }
    },
    imageHandler: (state, action: PayloadAction<{ filename: string; filevalue: any }>) => {
      const { filename, filevalue } = action.payload;
      if (filename === "kk") {
        state.kk = {
          filename: filevalue.name,
          filevalue: filevalue,
        };
      }
      if (filename === "ktp") {
        state.ktp = {
          filename: filevalue.name,
          filevalue: filevalue,
        };
      }
    },
    updateWarga: (state, { payload }) => {
      const [findData] = state.listWarga.filter((data: any) => data.id === payload);

      if (findData !== undefined) {
        state.modalDisplay = 1;
        state.showModal = !state.showModal;
        state.id = findData.id;
        state.homeDetailId = findData.homeDetailId;
        state.editMode = true;
        state.editValue = findData;
        state.lastDatePayment = findData.lastDatePayment;
        state.dateOfBirth = findData.dateOfBirth;
        state.ktp = {
          filename: findData.ktpImage,
          filevalue: findData.ktpImage,
        };
        state.kk = {
          filename: findData.kkImage,
          filevalue: findData.kkImage,
        };
      }
    },
    handleDate: (state, { payload }: PayloadAction<{ input: string; dateInput: number }>) => {
      const { input, dateInput } = payload;

      if (dateInput === 1) {
        state.lastDatePayment = input;
      }

      if (dateInput === 2) {
        state.dateOfBirth = input;
      }
    },
    handleChange: (state, { payload }) => {
      state.currentPage = payload;
    },
    handleShowPass: (state, { payload }) => {
      if (payload === "password") {
        state.isShowPassword = !state.isShowPassword;
      } else {
        state.isShowConfPassword = !state.isShowConfPassword;
      }
    },
    cleanDetailModal: (state) => {
      state.isFamilyLoading = true;
      state.listFamily = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllWarga.fulfilled, (state, { payload }) => {
        const { data, count, komplek } = payload;
        return {
          ...state,
          isLoading: false,
          listWarga: data,
          count,
          listKomplek: komplek,
        };
      })
      .addCase(getAllWarga.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createWarga.pending, (state) => {
        state.isDisabled = true;
        state.isShowConfPassword = false;
        state.isShowPassword = false;
      })
      .addCase(createWarga.fulfilled, (state) => {
        state.isDisabled = false;
        state.dateOfBirth = Date();
      })
      .addCase(createWarga.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(editWarga.pending, (state) => {
        state.isDisabled = true;
      })
      .addCase(editWarga.fulfilled, (state, { payload }) => {
        const { data } = payload;
        const index = state.listWarga.findIndex((prevData: any) => prevData.id === data.id);
        state.isDisabled = false;
        state.listWarga[index] = data;
      })
      .addCase(editWarga.rejected, (state) => {
        state.isDisabled = false;
      })
      .addCase(getListFamily.pending, (state) => {
        state.isFamilyLoading = true;
      })
      .addCase(getListFamily.fulfilled, (state, { payload }) => {
        state.isFamilyLoading = false;
        state.listFamily = payload.data;
      })
      .addCase(getListFamily.rejected, (state) => {
        state.isFamilyLoading = false;
      });
  },
});
export const {
  showPopupModal,
  showDetailWarga,
  showServicePopModal,
  handleShowPass,
  updateWarga,
  imageHandler,
  handleChange,
  handleDate,
  cleanDetailModal,
} = slice.actions;
export default slice.reducer;

export const getAllWarga = createAsyncThunk(
  "getAllWarga",
  async (data: { totalItem: number; currentPage: number }) => {
    try {
      const response = await axiosInstance.get(
        `/warga?totalItem=${data.totalItem}&page=${data.currentPage}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const getListFamily = createAsyncThunk("getListFamily", async (id: number) => {
  try {
    const response = await axiosInstance.get(`/warga-family?home=${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const createWarga = createAsyncThunk("createWarga", async (data: any) => {
  try {
    const response = await axiosInstance.post(`/warga`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const editWarga = createAsyncThunk(
  "editWarga",
  async (data: { id: string; homeDetailId: number; value: any }) => {
    try {
      const response = await axiosInstance.put(
        `/warga/${data.id}/${data.homeDetailId}`,
        data.value
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const deleteWarga = createAsyncThunk("deleteWarga", async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/warga/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});
