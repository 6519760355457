import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "src/utils/axios";

interface state {
  listComplaint: any[];
  editMode: boolean;
  isLoading: boolean;
  showModal: boolean;
  image: string;
  id: string;
  editValue: {};
  count: number;
  currentPage: number;
  totalItem: number;
}

const initialState: state = {
  listComplaint: [],
  editMode: false,
  isLoading: true,
  showModal: false,
  image: "",
  id: "",
  editValue: {},
  count: 1,
  currentPage: 1,
  totalItem: 20,
};

const slice = createSlice({
  name: "complaint warga",
  initialState,
  reducers: {
    handleChange: (state, { payload }) => {
      state.currentPage = payload;
    },
    showPopupModal: (state) => {
      state.showModal = !state.showModal;
      state.image = "";
    },
    showImageModal: (state, { payload }) => {
      const [findComplaint] = state.listComplaint.filter((prev) => prev.id === payload);

      if (findComplaint !== undefined) {
        state.image = process.env.REACT_APP_CLIENT_URL + findComplaint.image;
        state.showModal = !state.showModal;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllComplaint.fulfilled, (state, { payload }) => {
        const { data, count } = payload;
        return {
          ...state,
          isLoading: false,
          listComplaint: data,
          count,
        };
      })
      .addCase(getAllComplaint.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
export const { handleChange, showPopupModal, showImageModal } = slice.actions;
export default slice.reducer;

export const getAllComplaint = createAsyncThunk(
  "getAllComplaint",
  async (data: { totalItem: number; currentPage: number }) => {
    try {
      const response = await axiosInstance.get(
        `/complaint?totalItem=${data.totalItem}&page=${data.currentPage}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const editComplaint = createAsyncThunk("editComplaint", async (data: any) => {
  try {
    const response = await axiosInstance.put(`/complaint`, data);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});
