import { FC, ReactNode, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch } from "src/redux/hooks";
import { setUserData } from "src/redux/auth";

import Sidebar from "./Sidebar";
import Header from "./Header";

interface SidebarLayoutProps {
  children?: ReactNode;
}

const MainWrapper = styled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            padding-left: ${theme.sidebar.width};
        }
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow: auto;
`
);

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getToken = localStorage.getItem(process.env.REACT_APP_AUTH as string);
    if (getToken === null) {
      navigate("/", { replace: true });
    } else {
      dispatch(setUserData());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Sidebar />
      <MainWrapper>
        <Header />
        <MainContent>
          <Outlet />
        </MainContent>
      </MainWrapper>
    </>
  );
};

export default SidebarLayout;
